import React from "react";
import Modal from "@mui/material/Modal";
import { Box, Button } from "@mui/material";
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./PaymentSuccess.css";
import "./PaymentCancel.css"
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    minWidth: "350px",
    minHeight: "300px",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFFFFF",
    borderRadius: "10px",
    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
    padding: "20px",
};

const PaymentCancel = () => {
    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;
    const UserUuid = localStorage.getItem('UserUuid')
    const navigate = useNavigate();

    const [openBox, setOpenBox] = useState(true);

    const handleHomenavigate = () => {
        navigate('/');
        localStorage.removeItem("BillingCountryCode")
        localStorage.removeItem("BillingDays")
        localStorage.removeItem("BillingData")
        localStorage.removeItem("BillingPrice")
        localStorage.removeItem("BillingPlan")
        localStorage.removeItem("BillingUid")
        localStorage.removeItem("PlanType")
        localStorage.removeItem("BillingRegionCode")

        localStorage.removeItem("PlanUID")
        localStorage.removeItem("RegionName")
        localStorage.removeItem("ICCID")
    }

    // To prevent the back and front navigation from this page.
    useEffect(() => {
        const blockNavigation = () => { window.history.pushState(null, "", window.location.href); };

        blockNavigation();

        window.addEventListener("popstate", blockNavigation);

        return () => {
            window.removeEventListener("popstate", blockNavigation);
        };
    }, []);

    // This is for expiring the payment session
    const ExpireSession = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/expire_session`, requestOptions)
            .then((response) => response.json())
            .then((result) => {

            })
    }

    useEffect(() => {
        ExpireSession()
    }, [])

    return (
        <>
            <Modal
                open={openBox}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle} className="payment_failed">
                    <h2>
                        Payment Failed
                        <HighlightOffIcon
                            onClick={handleHomenavigate}
                            className="error-icon"
                        />
                    </h2>
                    <p>Please try again purchasing your plan.</p>
                    <Button
                        className="retry-button"
                        variant="contained"
                        onClick={handleHomenavigate}
                    >
                        Close
                    </Button>
                </Box>
            </Modal>
        </>
    );
};



export default PaymentCancel;
