import React from 'react'
import { useState, useEffect } from 'react'
import './SelectedRegionPlans.css'
import Flag from 'react-world-flags';
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Button, Modal, Autocomplete, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { H2 } from "../Typography";
import { Bars } from 'react-loader-spinner';
import NoPlanImg from '../../Assets/Empty.gif';
import Logo_4 from '../../Assets/Logo_4.png';
import { getAllInfoByISO } from 'iso-country-currency';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import MovieCreationIcon from '@mui/icons-material/MovieCreation';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import DuoIcon from '@mui/icons-material/Duo';
import AddTaskIcon from '@mui/icons-material/AddTask';
import ClearIcon from '@mui/icons-material/Clear';
import { countries } from 'country-data';
import FAQ from '../HomePage/FAQ/FAQ';
import Footer from '../HomePage/Footer/Footer';
import CheckIcon from '@mui/icons-material/Check';
import SimCardIcon from '@mui/icons-material/SimCard';
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import LocationSearchingOutlinedIcon from '@mui/icons-material/LocationSearchingOutlined';
import WorldImageForCard from '../../Assets/WorldImageForCard.svg'
import WorldImage from '../../Assets/WorldImage.svg'
import RegionCountryList from '../HomePage/Landing Page/RegionCountryList';

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "#FFF",
    boxShadow: 24,
    p: 4,
    borderRadius: "20px",
    // width: window.innerWidth <= 650 ? '90%' : 'auto',
};


const SelectedRegionPlans = () => {

    const REACT_APP_BASE_URL = process.env.REACT_APP_BASE_URL;

    const navigate = useNavigate();
    const [showModal, setShowModal] = useState(false);
    const RegionName = localStorage.getItem('RegionName');
    const RegionISO3Code = localStorage.getItem('regionCode')

    const UserUuid = localStorage.getItem("UserUuid");

    const [PlanType, setPlanType] = useState("5");
    const [PlansList, setPlansList] = useState([]);
    const [NetworkName, setNetworkName] = useState("");
    const [FiveGnetwork, setFiveGnetwork] = useState("");
    const [FourGnetwork, setFourGnetwork] = useState("");
    const [PlansListShowLoader, setPlansListShowLoader] = useState("");

    const CheckFirstTimeIccid = localStorage.getItem("CheckFirstTimeIccid")

    // modal state for purchase new iccid
    const [OpenPurchaseNewIccidModal, setOpenPurchaseNewIccidModal] = useState(false)

    const [TotalIccids, setTotalIccids] = useState([])
    const [ChooseIccids, setChooseIccids] = useState('');
    const [NoDataResponse, setNoDataResponse] = useState(false);

    const IncludedPageLink = "https://help.digiesim.com/hc/en-us/articles/27446148143377-What-s-Included";
    const HelpCentrePageLink = "https://help.digiesim.com/hc/en-us";

    // For open the page to the top 
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleChange = (event, newValue) => {
        setPlanType(newValue);
        setPlansListShowLoader("");
    };

    const RegionsCodeArray = [
        { code: "EUR", name: "EUROPE+ Travel eSIM", regionCode: "europe" },
        { code: "APC", name: "ASIA+ Travel eSIM", regionCode: "apac" },
        { code: "MEA", name: "MIDDLE EAST+ Travel eSIM", regionCode: "mena" },
        { code: "LAT", name: "LATIN AMERICA+ Travel eSIM", regionCode: "latam" },
        { code: "BLK", name: "BALKANS+ Travel eSIM", regionCode: "balkans" },
        { code: "OC", name: "OCEANIA+ Travel eSIM", regionCode: "oceania" },
        { code: "CAU", name: "CAUCASUS+ Travel eSIM", regionCode: "caucasus" },
        { code: "CRB", name: "CARIBBEAN+ Travel eSIM", regionCode: "caribbean" },
    ];

    const FindRegionCode = (isoCode) => {
        const region = RegionsCodeArray.find((region) => region.code === isoCode);
        return region ? region.regionCode : null;
    };

    const ShowPlans = () => {

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "days": PlanType,
            "region": FindRegionCode(RegionISO3Code)
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/get_regional_plan_type`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                setPlansList(result.regional_plan_type_datas);
                setPlansListShowLoader(result.result);
                // setNetworkName(result.network_list_response.networks[0].network_name);
                // setFiveGnetwork(result.network_list_response.networks[0].support_5g)
                // setFourGnetwork(result.network_list_response.networks[0].support_lte)
            })
    };

    useEffect(() => {
        ShowPlans();
    }, [PlanType]);



    // Getting total iccids

    const GetTotalIccids = () => {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const raw = JSON.stringify({
            "user_uuid": UserUuid
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        fetch(`${REACT_APP_BASE_URL}/all_iccid`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                // console.log(result)
                if (result.Msg == "No iccid fount " || result.ResponseCode === 404) {
                    setNoDataResponse(true);
                } else {
                    setNoDataResponse(false)
                    setTotalIccids(result.user_sims)
                }
            })
            .catch((error) => {
                // console.error(error)
            });
    }

    useEffect(() => {
        if (UserUuid) {
            GetTotalIccids();
        }
    }, [])

    // function for purchase new iccid
    const handleModalForCreateIccid = (uid) => {
        localStorage.setItem("PlanUID", uid);
        localStorage.setItem("CheckPlanCategory", "RegionPlan")

        if (CheckFirstTimeIccid == "") {
            navigate('/payment');
            window.scrollTo(0, 0);
        } else {
            setOpenPurchaseNewIccidModal(true);
        }
    }


    // call this function when the user logout 
    const handleNavigate = (uid) => {
        localStorage.setItem("CheckPlanCategory", "RegionPlan")
        localStorage.setItem("redirectAfterLogin", '/region_plans');
        localStorage.setItem("PlanUID", uid);
        setShowModal(true);
    };

    // close modal function 
    const handleClosePurchaseIccidModal = () => {
        setOpenPurchaseNewIccidModal(false);
        setChooseIccids('');
        localStorage.removeItem('PlanUID');
    }

    // navigate payment page function
    const handleProceedButton = () => {
        navigate('/payment');
        window.scrollTo(0, 0);
        localStorage.removeItem('ICCID');
    }

    // navigate payment page function
    const handleSelectPlan = () => {
        navigate('/payment');
        window.scrollTo(0, 0);
        localStorage.setItem("ICCID", ChooseIccids.split('-')[0])

    }



    const handleCloseModal = () => {
        setShowModal(false);
    };

    function getCountryName(iso3Code) {
        const country = countries[iso3Code];
        return country ? country.name : "Unknown country code";
    }

    const getCurrencySymbol = (iso3Code) => {
        try {
            const countryInfo = getAllInfoByISO(iso3Code);
            return countryInfo.currency.symbol;
        } catch (error) {
            return '';
        }
    };

    // const currencySymbol = getCurrencySymbol(SelectedCountry);

    const getPlanHeading = (dataQuotaMb, plan_type) => {
        const dataQuotaGb = dataQuotaMb / 1024;

        // Ensure plan_type is defined and not empty
        if (dataQuotaGb > 39 || (plan_type && plan_type.trim() !== "")) {
            return "Go Unlimited";
        }

        // Other conditions based on dataQuotaGb
        if (dataQuotaGb <= 0.5) {
            return "Get Connected";
        } else if (dataQuotaGb > 0.5 && dataQuotaGb <= 10) {
            return "Value Plus";
        } else if (dataQuotaGb > 10 && dataQuotaGb <= 39) {
            return "Stream Happy";
        } else {
            return "Get Connected";
        }
    };

    const [getConnectedModalOpen, setGetConnectedModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("Get Connected");

    const handleOpenModal = (title) => {
        setModalTitle(title);
        setGetConnectedModalOpen(true);
    };

    const handleCloseModals = () => {
        setGetConnectedModalOpen(false);
    };

    const getFeaturesList = (dataQuotaMb) => {
        const dataQuotaGb = dataQuotaMb / 1024;
        let title = "Get Connected";

        if (dataQuotaGb <= 0.5) {
            title = "Get Connected";
            return [
                { icon: <LocationSearchingOutlinedIcon style={{ marginRight: '8px' }} />, text: "Multi Destination" },
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing Value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for Whatsapp" },
                { icon: <AccessTimeIcon style={{ marginRight: '8px' }} />, text: "24/7 support" },
                {
                    icon: (
                        <div className='What_Included'

                        // onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 0.5 && dataQuotaGb <= 10) {
            title = "Value Plus";
            return [
                { icon: <LocationSearchingOutlinedIcon style={{ marginRight: '8px' }} />, text: "Multi Destination" },
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing Value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for Whatsapp" },
                { icon: <AccessTimeIcon style={{ marginRight: '8px' }} />, text: "24/7 support" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 10 && dataQuotaGb <= 39) {
            title = "Stream Happy";
            return [
                { icon: <LocationSearchingOutlinedIcon style={{ marginRight: '8px' }} />, text: "Multi Destination" },
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing Value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for Whatsapp" },
                { icon: <AccessTimeIcon style={{ marginRight: '8px' }} />, text: "24/7 support" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else if (dataQuotaGb > 39) {
            title = "Go Unlimited";
            return [
                { icon: <LocationSearchingOutlinedIcon style={{ marginRight: '8px' }} />, text: "Multi Destination" },
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing Value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for Whatsapp" },
                { icon: <AccessTimeIcon style={{ marginRight: '8px' }} />, text: "24/7 support" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        } else {
            return [
                { icon: <LocationSearchingOutlinedIcon style={{ marginRight: '8px' }} />, text: "Multi Destination" },
                { icon: <VerifiedUserIcon style={{ marginRight: '8px' }} />, text: "Amazing Value" },
                { icon: <WhatsAppIcon style={{ marginRight: '8px' }} />, text: "Great for Whatsapp" },
                { icon: <AccessTimeIcon style={{ marginRight: '8px' }} />, text: "24/7 support" },
                {
                    icon: (
                        <div className='What_Included'

                            onClick={() => handleOpenModal(title)}
                        >
                            <AddTaskIcon className='Icon' style={{ color: 'indianred' }} />
                            <span >What's Included</span>
                        </div>
                    ),
                }
            ];
        }
    };

    return (
        <>
            <div className='SelectedRegionPlan'>
                <div className='one' >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={WorldImage} style={{ height: '50px', }} />
                        <h1>{RegionName} eSIM <span style={{ color: '#EA857A' }}>Plans & Pricing</span></h1>
                    </div>
                    <p>Pick your plan duration, go Prepaid or Unlimited, and get ready to connect!</p>
                </div>

                <div className='two'>
                    <TabContext value={PlanType}>
                        <Box id="tabs">
                            <TabList onChange={handleChange} variant="scrollable" >
                                <Tab label="5 Days" value="5" />
                                <Tab label="10 Days" value="10" />
                                <Tab label="15 Days" value="15" />
                                <Tab label="30 Days" value="30" />
                            </TabList>
                        </Box>

                        <TabPanel value={PlanType} className='tabPanel'>
                            {PlansListShowLoader ? (
                                PlansList?.length === 0 ? (
                                    <div style={{
                                        width: '75vw', height: '40vh',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        textAlign: 'center'
                                    }}>
                                        <img src={NoPlanImg} id='NoPlanImg' style={{ height: '180px', alignItems: 'center' }} />
                                        <h2 style={{ margin: '10px', fontWeight: '300' }}>There is no such plan currently.</h2>
                                    </div>
                                ) : (
                                    PlansList.sort((a, b) => {
                                        const priority = { "Get Connected": 1, "Value Plus": 2, "Stream Happy": 3, "Go Unlimited": 4 };
                                        return (priority[getPlanHeading(a.data_quota_mb)] || 0) - (priority[getPlanHeading(b.data_quota_mb)] || 0);
                                    }).map((plan, index) => (
                                        <div key={index} className='Card'>
                                            <div className='Card1'>
                                                <h1>{getPlanHeading(plan.data_quota_mb)}</h1>
                                            </div>
                                            <div className="Card2">
                                                <img src={WorldImageForCard} style={{ height: '40px', marginRight: '0px' }} />
                                                <h2>{RegionISO3Code}</h2>
                                                <h3>
                                                    {plan.plan_type || `${plan.data_quota_mb < 1024 ? `${plan.data_quota_mb} MB` : `${(plan.data_quota_mb / 1024).toFixed(0)} GB`}`}
                                                </h3>
                                            </div>
                                            <div className="Card3">
                                                <div className='features'>
                                                    {getFeaturesList(plan.data_quota_mb).map((feature, idx) => (
                                                        <h2 key={idx} className='feature-item'>{feature.icon}{feature.text}</h2>
                                                    ))}
                                                </div>
                                                <div id='PriceBox' >
                                                    <Button className='button1'>${plan.price}</Button>
                                                    {UserUuid ? (
                                                        <Button className='button2' onClick={() => handleModalForCreateIccid(plan.uid)}>Buy Now</Button>) :
                                                        (<Button className='button2' onClick={() => handleNavigate(plan.uid)}>Buy Now</Button>)}
                                                </div>
                                                {/* <div className='end-sec'>
                                                    <h2><SignalCellularAltIcon className='sim_icon' /><span>{NetworkName}</span></h2>

                                                    <h1>
                                                        {FiveGnetwork ? (
                                                            <span>5<span id='networkLabel'>G</span></span>
                                                        ) : FourGnetwork ? (
                                                            <span>4<span>G</span></span>
                                                        ) : (
                                                            <span style={{ fontSize: '20px' }} >No Network</span>
                                                        )}
                                                    </h1>

                                                </div> */}
                                            </div>
                                        </div>
                                    ))
                                )
                            ) : (
                                <div style={{ width: '75vw', height: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Bars height="30" width="30" color="#EA857A" />
                                </div>
                            )}
                        </TabPanel>
                    </TabContext>
                </div>
            </div >

            <RegionCountryList />

            <div id='searchFaq'>
                <FAQ />
            </div>

            <Footer />

            <Modal
                open={showModal}
                onClose={handleCloseModal}
            >
                <Box sx={style} className="redirect_modal" >
                    <h2 >Login Required</h2>
                    <p >You need to log in to proceed with the buy.</p>
                    <Button variant="contained" color="primary" onClick={() => navigate('/login')}>
                        Go to Login
                    </Button>
                </Box>
            </Modal>

            <Modal
                open={getConnectedModalOpen}
                onClose={handleCloseModals}
            >
                <Box sx={style} className="modal-box">
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '13px' }}>
                        <h3 style={{ fontWeight: '700', fontSize: '24px', background: '#E7EB14', padding: '8px', borderRadius: '5px' }}>{modalTitle} </h3>
                        <ClearIcon style={{ cursor: 'pointer', color: 'indianred' }} onClick={handleCloseModals} />
                    </div>

                    {/* <hr /> */}

                    <div className='IncludedModal'>
                        {(modalTitle === 'Get Connected' || modalTitle === 'Value Plus' || modalTitle === 'Stream Happy') && (
                            <div className="box1">
                                <h2> What’s Included:</h2>

                                <div id='info' >
                                    <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' > Purchase with confidence with our <span> Money back guarantee policy. </span> </a>
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' /> Install your eSIM via the QR code that is emailed to you
                                    .</div>
                                <div id='info'>
                                    <CheckIcon className='icon' /> Set an auto top up if you need more data.</div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' >
                                        <span>Find out more</span> about our plans at the  <a href={HelpCentrePageLink} target='_blank' > <span>help centre. </span></a> </a>
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={HelpCentrePageLink} target='_blank' >  24/7 Support via our <span>help centre.</span></a> </div>
                            </div>
                        )}

                        {modalTitle === 'Go Unlimited' && (
                            <div className="box1">
                                <h2> What’s Included:</h2>
                                <div id='info'> <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' > Purchase with confidence with our <span> Money back guarantee policy. </span> </a>
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    Set an auto top up if you need more data.
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' > After 3GB of daily high speed data, use UNLIMITED LTE data of 1Mbps. <span>Find out more.</span> </a>
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' > Unlimited plans are subject to our <span>Fair Use Policy.</span>  </a>
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' /> Install your eSIM via the QR code that us emailed to you.
                                </div>
                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={IncludedPageLink} target='_blank' >
                                        <span>Find out more</span> about our plans on our  <a href={HelpCentrePageLink} target='_blank' > <span>help centre. </span></a> </a>
                                </div>


                                <div id='info'>
                                    <CheckIcon className='icon' />
                                    <a href={HelpCentrePageLink} target='_blank' >  24/7 Support via our <span>help centre.</span></a>
                                </div>
                            </div>
                        )}
                    </div>

                </Box>

            </Modal>

            {/* modal for purchase new iccid */}

            <Modal
                open={OpenPurchaseNewIccidModal}
                onClose={handleClosePurchaseIccidModal}
            >
                <Box sx={style} className="Purchase_iccidModal" >
                    <ClearIcon style={{
                        cursor: 'pointer',
                        color: 'black',
                        position: 'absolute',
                        top: 15,
                        right: 15
                    }} onClick={handleClosePurchaseIccidModal} />
                    <p >Either Top up the selected eSIM or get a New  eSIM for your next destination.</p>
                    <div className='Box_1'>
                        <Autocomplete className='Autocomplete'

                            disablePortal={true}
                            size="small"

                            value={ChooseIccids}
                            onChange={(event, newValue) => {
                                // Only set the new value if it is not "No data available"
                                if (newValue !== "No data available") {
                                    setChooseIccids(newValue);

                                }
                            }}
                            options={!NoDataResponse ? TotalIccids.map((item) => item.iccid + "-" + (item.country).toUpperCase()) : ["No data available"]}
                            sx={{ width: 300, margin: '0px 20px 0px 0px' }}
                            renderInput={(params) => <TextField {...params} label="Select eSIM" />}
                            isOptionEqualToValue={(option, value) => option === value && option !== "No data available"}
                            getOptionDisabled={(option) => option === "No data available"}
                        />



                        {ChooseIccids ?
                            (<Button className='selectbtn' variant="contained" color="primary" onClick={handleSelectPlan}>
                                Top up
                            </Button>) :
                            (<Button className='selectbtn' disabled variant="contained" color="primary" >
                                Top up
                            </Button>)
                        }

                    </div>

                    <p style={{ fontSize: '24px ', color: 'indianred' }}>OR</p>



                    {ChooseIccids ? (<Button variant="contained" className='Proceedbtn' disabled color="primary">
                        New eSIM
                    </Button>) : (<Button variant="contained" className='Proceedbtn' color="primary" onClick={handleProceedButton}>
                        New eSIM
                    </Button>)}

                </Box>
            </Modal>
        </>
    )
}

export default SelectedRegionPlans;